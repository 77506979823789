import { FaDiscord, FaTwitter } from 'react-icons/fa'

export const DISCORD_INVITE_LINK = 'https://discord.gg/9Z9xUHzKBw'

export function DiscordLink({ size = 32 }) {
  return (
    <a
      href={DISCORD_INVITE_LINK}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer hover:scale-110"
      title="Join our Discord server"
    >
      <FaDiscord size={size} />
    </a>
  )
}

export function TwitterLink({ size = 32 }) {
  const twitterLink = 'https://twitter.com/solidten_ai'

  return (
    <a
      href={twitterLink}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer hover:scale-110"
      title="Follow us on Twitter"
    >
      <FaTwitter size={size} />
    </a>
  )
}
